import React, { useState } from 'react';
import './AdminUpload.css';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'fer2022') {
      onLogin();
    } else {
      setError('Contraseña incorrecta');
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="lock-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 2553.4 2553.4">
            <circle cx="1276.7" cy="1276.7" r="1276.7" style={{ fill: '#00b1d4' }}/>
            <path d="M1277 2553c0-145-3-226-86-359a596 596 0 0 0-473-263c143 0 361-74 473-263 69-116 81-175 85-279 2-45-1-449-3-519-8-219 120-399 323-391" style={{ fill: 'none', stroke: '#fefefe', strokeWidth: '301.58', strokeLinejoin: 'bevel', strokeMiterlimit: '22.9256' }}/>
          </svg>
        </div>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="input-container">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              className="password-input"
            />
            <button type="submit" className="submit-arrow">→</button>
          </div>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Login;