import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { ref as dbRef, get, update, push, increment, onDisconnect, set, off, onValue } from 'firebase/database';
import axios from 'axios';

const PDFViewerPage = () => {
  const { shortUrl } = useParams();
  const location = useLocation();
  const isTracked = !location.pathname.includes('u/');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const viewIdRef = useRef(null);
  const disconnectRef = useRef(null);
  const activeRef = useRef(true);
  const pdfIdRef = useRef(null);

  const getIpAndLocation = async () => {
    try {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const ip = ipResponse.data.ip;

      const locationResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
      const { city, region, country_name, latitude, longitude } = locationResponse.data;

      return { 
        ip, 
        city,
        region,
        country: country_name, 
        lat: latitude, 
        lon: longitude 
      };
    } catch (error) {
      console.error('Error obteniendo IP y ubicación:', error);
      return null;
    }
  };

  const handleClosePdf = useCallback(async () => {
    if (viewIdRef.current && activeRef.current && pdfIdRef.current) {
      const viewRef = dbRef(db, `pdfs/${pdfIdRef.current}/viewDetails/${viewIdRef.current}`);
      off(viewRef);
      if (disconnectRef.current) {
        disconnectRef.current.cancel();
      }
      await update(viewRef, {
        closeTimestamp: new Date().toISOString(),
        event: "closed",
        active: false
      });
      activeRef.current = false;
    }
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden && activeRef.current) {
      handleClosePdf();
    }
  }, [handleClosePdf]);

  const setupDisconnectHandler = useCallback(() => {
    if (viewIdRef.current && pdfIdRef.current) {
      const viewRef = dbRef(db, `pdfs/${pdfIdRef.current}/viewDetails/${viewIdRef.current}`);
      disconnectRef.current = onDisconnect(viewRef);
      disconnectRef.current.update({
        closeTimestamp: new Date().toISOString(),
        event: "disconnected",
        active: false
      });
    }
  }, []);

  const handleBeforeUnload = useCallback(() => {
    handleClosePdf();
  }, [handleClosePdf]);

  useEffect(() => {
    let isMounted = true;
    const fetchPdfData = async () => {
      try {
        console.log("Fetching PDF data...");
        // Primero, obtener el ID de Firebase usando la URL corta
        const shortUrlRef = dbRef(db, `shortUrls/${shortUrl}`);
        const shortUrlSnapshot = await get(shortUrlRef);
        
        if (!shortUrlSnapshot.exists()) {
          throw new Error("URL no válida");
        }
        
        const pdfId = shortUrlSnapshot.val();
        pdfIdRef.current = pdfId;
        const pdfRef = dbRef(db, `pdfs/${pdfId}`);
        const snapshot = await get(pdfRef);

        if (snapshot.exists() && isMounted) {
          setPdfUrl(snapshot.val().url);
          console.log("PDF data fetched:", snapshot.val());

          if (isTracked) {
            await update(pdfRef, { views: increment(1) });

            const locationData = await getIpAndLocation();

            const uniqueViewId = push(dbRef(db, `pdfs/${pdfId}/viewDetails`)).key;
            viewIdRef.current = uniqueViewId;

            const viewRef = dbRef(db, `pdfs/${pdfId}/viewDetails/${uniqueViewId}`);
            const viewData = {
              timestamp: new Date().toISOString(),
              ...locationData,
              userAgent: navigator.userAgent,
              event: "opened",
              active: true
            };
            await set(viewRef, viewData);

            setupDisconnectHandler();

            // Set up periodic active check
            const activeCheckInterval = setInterval(() => {
              if (activeRef.current) {
                update(viewRef, { lastActiveCheck: new Date().toISOString() });
              } else {
                clearInterval(activeCheckInterval);
              }
            }, 60000); // Check every minute

            // Set up visibility change listener
            document.addEventListener('visibilitychange', handleVisibilityChange);

            // Set up beforeunload listener
            window.addEventListener('beforeunload', handleBeforeUnload);

            // Set up Firebase connection state listener
            const connectedRef = dbRef(db, '.info/connected');
            onValue(connectedRef, (snap) => {
              if (snap.val() === true) {
                console.log("Connected to Firebase");
                setupDisconnectHandler();
              } else {
                console.log("Disconnected from Firebase");
                handleClosePdf();
              }
            });

            return () => {
              clearInterval(activeCheckInterval);
              document.removeEventListener('visibilitychange', handleVisibilityChange);
              window.removeEventListener('beforeunload', handleBeforeUnload);
              off(connectedRef);
            };
          }
        } else if (isMounted) {
          setError("¡No se encontró el documento!");
        }
      } catch (err) {
        console.error("Error al obtener el documento:", err);
        if (isMounted) {
          setError("No se pudo cargar el PDF. Por favor, verifica tu conexión o intenta más tarde.");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchPdfData();

    return () => {
      isMounted = false;
      if (isTracked) {
        handleClosePdf();
      }
    };
  }, [shortUrl, isTracked, handleBeforeUnload, handleClosePdf, handleVisibilityChange, setupDisconnectHandler]);

  if (loading) return <div>Cargando PDF...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ 
      position: 'absolute', 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0, 
      overflow: 'hidden'
    }}>
      {pdfUrl ? (
        <iframe
          src={`${pdfUrl}#toolbar=0`}
          width="100%"
          height="100%"
          title="Visor de PDF"
          style={{ 
            border: "none",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        ></iframe>
      ) : (
        <div>No se pudo mostrar el PDF. Por favor, inténtalo más tarde.</div>
      )}
    </div>
  );
};

export default PDFViewerPage;