import axios from 'axios';
import UAParser from 'ua-parser-js';
import { ref as dbRef, get } from 'firebase/database';
import { db } from '../firebaseConfig';

export const getMultipleIps = async () => {
  const services = [
    { name: 'ipify', url: 'https://api.ipify.org?format=json' },
    { name: 'ipwhois', url: 'http://ipwho.is/' },
    { name: 'ipapi', url: 'https://ipapi.co/json/' },
    { name: 'ipgeolocation', url: 'https://api.ipgeolocation.io/ipgeo?apiKey=TU_API_KEY' }
  ];

  const ips = {};
  let locationInfo = null;

  for (const service of services) {
    try {
      const response = await axios.get(service.url);
      ips[service.name] = response.data.ip;

      if (!locationInfo && service.name === 'ipwhois') {
        locationInfo = {
          city: response.data.city,
          region: response.data.region,
          country: response.data.country,
          lat: response.data.latitude,
          lon: response.data.longitude
        };
      }
    } catch (error) {
      console.error(`Error obteniendo IP de ${service.name}:`, error);
      ips[service.name] = 'Error';
    }
  }

  return { ips, locationInfo };
};

export const calculateViewStats = (viewDetails) => {
  if (!viewDetails) return { uniqueViews: 0, totalViews: 0 };

  const viewDetailsArray = Object.values(viewDetails);
  const uniqueIPs = new Set(viewDetailsArray.map(detail => detail.ip));

  return {
    uniqueViews: uniqueIPs.size,
    totalViews: viewDetailsArray.length
  };
};

export const calculateTotalViewDuration = (viewDetails) => {
  if (!viewDetails) return 0;

  const viewDetailsArray = Object.values(viewDetails);
  const totalDuration = viewDetailsArray.reduce((total, detail) => {
    if (detail.timestamp && detail.closeTimestamp) {
      const duration = new Date(detail.closeTimestamp) - new Date(detail.timestamp);
      return total + duration;
    }
    return total;
  }, 0);

  const minutes = Math.floor(totalDuration / 60000);
  const seconds = Math.floor((totalDuration % 60000) / 1000);

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const parseUserAgent = (userAgent) => {
  const parser = new UAParser(userAgent);
  const result = parser.getResult();
  
  let osName = result.os.name;
  let osVersion = result.os.version;
  if (result.os.name === 'Windows' && result.os.version === '10') {
    if (userAgent.indexOf('Windows NT 10.0; Win64; x64') !== -1 && 
        userAgent.indexOf('Chrome/') !== -1 && 
        parseInt(result.browser.version) >= 90) {
      osName = 'Windows';
      osVersion = '11';
    }
  }

  return {
    browser: `${result.browser.name} ${result.browser.version}`,
    os: `${osName} ${osVersion}`,
    device: result.device.type ? `${result.device.vendor} ${result.device.model}` : 'Desktop'
  };
};

export const getLatestViewDetails = (viewDetails) => {
  if (!viewDetails) return null;
  const sortedViews = Object.values(viewDetails).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  return sortedViews[0];
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  return `${day}/${month}`;
};

export const calculateViewDuration = (openTimestamp, closeTimestamp) => {
  if (!openTimestamp || !closeTimestamp) return 'N/A';
  const duration = new Date(closeTimestamp) - new Date(openTimestamp);
  const minutes = Math.floor(duration / 60000);
  const seconds = ((duration % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const formatPlatform = (detail, parsedUA) => {
  const browserVersion = parsedUA.browser.split(' ')[1].split('.').slice(0, 1).join('.');
  const osName = parsedUA.os.startsWith('Windows') ? `Win${parsedUA.os.split(' ')[1]}` : parsedUA.os;
  return `${detail.ip} ${detail.city} ${parsedUA.device} ${parsedUA.browser.split(' ')[0]}${browserVersion} ${osName}`;
};

export const generateShortUrl = async () => {
  const vowels = 'aeiou';
  const consonants = 'bcdfghjklmnpqrstvwxyz';
  
  const generateRandomShortUrl = () => {
    return consonants[Math.floor(Math.random() * consonants.length)] +
           vowels[Math.floor(Math.random() * vowels.length)] +
           consonants[Math.floor(Math.random() * consonants.length)] +
           Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  };

  let attempts = 0;
  const maxAttempts = 10; // Establecemos un número máximo de intentos para evitar bucles infinitos

  while (attempts < maxAttempts) {
    const shortUrl = generateRandomShortUrl();
    
    // Verificar si la URL corta ya existe
    const shortUrlRef = dbRef(db, `shortUrls/${shortUrl}`);
    const snapshot = await get(shortUrlRef);
    
    if (!snapshot.exists()) {
      return shortUrl;
    }

    attempts++;
  }

  throw new Error('No se pudo generar una URL corta única después de múltiples intentos');
};