import React, { useState, useEffect } from 'react';
import { storage, db } from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { ref as dbRef, set, push, onValue, update, remove } from 'firebase/database';
import AdminUploadTable from './AdminUploadTable';
import Login from './login';
import { generateShortUrl } from './AdminUploadUtils';
import './AdminUpload.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Versión del componente
const VERSION = "1.3";

const AdminUpload = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [generatedLink, setGeneratedLink] = useState("");
  const [unTrackedLink, setUnTrackedLink] = useState("");
  const [pdfs, setPdfs] = useState([]);
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [obs, setObs] = useState("");
  const [generalFiles, setGeneralFiles] = useState({
    PDI: null,
    PTI: null,
    ECRAN: null
  });
  const [selectedGeneralFile, setSelectedGeneralFile] = useState(null);

  useEffect(() => {
    fetchPdfs();
    fetchGeneralFiles();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setSelectedGeneralFile(null);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if ((!file && !selectedGeneralFile) || !nombre || !telefono) {
      alert("Por favor, completa todos los campos obligatorios y selecciona un archivo.");
      return;
    }

    if (selectedGeneralFile) {
      uploadGeneralFileReference();
    } else {
      uploadFile(file);
    }
  };

  const uploadFile = async (fileToUpload) => {
    const storageRef = ref(storage, `pdfs/${fileToUpload.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileToUpload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        alert(`La subida falló: ${error.message}`);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const pdfsListRef = dbRef(db, 'pdfs');
          const newPdfRef = push(pdfsListRef);
          const shortUrl = await generateShortUrl();

          await set(newPdfRef, {
            name: fileToUpload.name,
            url: downloadURL,
            views: 0,
            viewDetails: {},
            nombre,
            telefono,
            obs,
            timestamp: new Date().toISOString(),
            shortUrl
          });

          // Guardar la relación entre la URL corta y el ID de Firebase
          await set(dbRef(db, `shortUrls/${shortUrl}`), newPdfRef.key);

          const trackedLink = `${window.location.origin}/${shortUrl}`;
          const untrackedLink = `${window.location.origin}/u/${shortUrl}`;
          setGeneratedLink(trackedLink);
          setUnTrackedLink(untrackedLink);

          setFile(null);
          setProgress(0);
          setNombre("");
          setTelefono("");
          setObs("");
        } catch (error) {
          console.error("Error generating link: ", error);
          alert(`Error al generar el enlace: ${error.message}`);
        }
      }
    );
  };

  const uploadGeneralFileReference = async () => {
    try {
      const generalFile = generalFiles[selectedGeneralFile];
      const pdfsListRef = dbRef(db, 'pdfs');
      const newPdfRef = push(pdfsListRef);
      const shortUrl = await generateShortUrl();

      await set(newPdfRef, {
        name: generalFile.name,
        url: generalFile.url,
        views: 0,
        viewDetails: {},
        nombre,
        telefono,
        obs,
        timestamp: new Date().toISOString(),
        isGeneralFile: true,
        generalFileType: selectedGeneralFile,
        shortUrl
      });

      // Guardar la relación entre la URL corta y el ID de Firebase
      await set(dbRef(db, `shortUrls/${shortUrl}`), newPdfRef.key);

      const trackedLink = `${window.location.origin}/${shortUrl}`;
      const untrackedLink = `${window.location.origin}/u/${shortUrl}`;
      setGeneratedLink(trackedLink);
      setUnTrackedLink(untrackedLink);

      setSelectedGeneralFile(null);
      setNombre("");
      setTelefono("");
      setObs("");
      alert("Referencia al archivo general creada exitosamente!");
    } catch (error) {
      console.error("Error creating general file reference:", error);
      alert(`Error al crear la referencia al archivo general: ${error.message}`);
    }
  };

  const fetchPdfs = () => {
    const pdfsRef = dbRef(db, 'pdfs');
    onValue(pdfsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const pdfsArray = Object.keys(data).map((key) => {
          const pdfData = data[key];
          return {
            id: key,
            ...pdfData,
          };
        });
        setPdfs(pdfsArray);
      } else {
        setPdfs([]);
      }
    });
  };

  const fetchGeneralFiles = () => {
    const generalFilesRef = dbRef(db, 'generalFiles');
    onValue(generalFilesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGeneralFiles(data);
      }
    });
  };

  const handleGeneralFileUpload = async (fileType) => {
    const file = await selectFile();
    if (!file) return;

    const storageRef = ref(storage, `generalFiles/${fileType}.pdf`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        alert(`La subida falló: ${error.message}`);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const generalFilesRef = dbRef(db, 'generalFiles');
          await update(generalFilesRef, {
            [fileType]: {
              name: file.name,
              url: downloadURL,
              timestamp: new Date().toISOString()
            }
          });
          alert(`Archivo ${fileType} actualizado exitosamente!`);
          fetchGeneralFiles();
        } catch (error) {
          console.error("Error updating general file:", error);
          alert(`Error al actualizar el archivo general: ${error.message}`);
        }
      }
    );
  };

  const handleGeneralFileDelete = async (fileType) => {
    if (!window.confirm(`¿Estás seguro de que quieres eliminar el archivo ${fileType}?`)) return;

    try {
      const storageRef = ref(storage, `generalFiles/${fileType}.pdf`);
      await deleteObject(storageRef);

      const generalFilesRef = dbRef(db, `generalFiles/${fileType}`);
      await remove(generalFilesRef);

      alert(`Archivo ${fileType} eliminado exitosamente`);
      fetchGeneralFiles();
    } catch (error) {
      console.error(`Error al eliminar el archivo ${fileType}:`, error);
      alert(`Error al eliminar el archivo ${fileType}: ${error.message}`);
    }
  };

  const selectFile = () => {
    return new Promise((resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/pdf';
      input.onchange = (e) => {
        resolve(e.target.files[0]);
      };
      input.click();
    });
  };

  const handleGeneralFileSelect = (fileType) => {
    setSelectedGeneralFile(fileType);
    setFile(null);
  };

  const buttonColors = {
    PDI: 'btn-warning',
    PTI: 'btn-warning',
    ECRAN: 'btn-warning'
  };

  if (!isLoggedIn) {
    return <Login onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <div className="admin-upload-container">
      <h2>Subir PDF</h2>
      <div className="upload-section">
        <form className="upload-form" onSubmit={handleUpload}>
          <div className="file-selection-container mb-3">
            <div className="d-flex align-items-center">
              <div className="custom-file me-3">
                <input type="file" className="custom-file-input" id="customFile" onChange={handleFileChange} accept="application/pdf" />
                <label className="custom-file-label" htmlFor="customFile">
                  {file ? file.name : "Seleccionar archivo"}
                </label>
              </div>
              {['PDI', 'PTI', 'ECRAN'].map((fileType) => (
                <div key={fileType} className="btn-group me-2">
                  <button 
                    type="button"
                    className={`btn ${buttonColors[fileType]} ${selectedGeneralFile === fileType ? 'active' : ''}`}
                    onClick={() => handleGeneralFileSelect(fileType)}
                    disabled={!generalFiles[fileType]}
                  >
                    {fileType}
                  </button>
                  <button 
                    type="button" 
                    className={`btn ${buttonColors[fileType]} dropdown-toggle dropdown-toggle-split`} 
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"
                  >
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleGeneralFileUpload(fileType); }}>Actualizar</a></li>
                    {generalFiles[fileType] && (
                      <>
                        <li><a className="dropdown-item" href={generalFiles[fileType].url} target="_blank" rel="noopener noreferrer">Ver</a></li>
                        <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleGeneralFileDelete(fileType); }}>Eliminar</a></li>
                      </>
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <input 
            type="text" 
            placeholder="Nombre" 
            value={nombre} 
            onChange={(e) => setNombre(e.target.value)} 
          />
          <input 
            type="tel" 
            placeholder="Teléfono" 
            value={telefono} 
            onChange={(e) => setTelefono(e.target.value)} 
          />
          <textarea 
            placeholder="Observaciones" 
            value={obs} 
            onChange={(e) => setObs(e.target.value)}
          ></textarea>
          <button type="submit">Subir</button>
        </form>
        <div className="progress-bar">
          <div className="progress-bar-fill" style={{ width: `${progress}%` }}>
            {progress.toFixed(0)}%
          </div>
        </div>
        {generatedLink && (
          <div>
            <h3>Enlaces generados:</h3>
            <p>Con rastreo: <a href={generatedLink} target="_blank" rel="noopener noreferrer">{generatedLink}</a></p>
            <p>Sin rastreo: <a href={unTrackedLink} target="_blank" rel="noopener noreferrer">{unTrackedLink}</a></p>
          </div>
        )}
      </div>
      <AdminUploadTable pdfs={pdfs} fetchPdfs={fetchPdfs} />
    </div>
  );
};

export default AdminUpload;