// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA1CudslNtS9UgIKGDN0VFVXqvXflW94hc",
  authDomain: "visorpdf3.firebaseapp.com",
  databaseURL: "https://visorpdf3-default-rtdb.firebaseio.com",
  projectId: "visorpdf3",
  storageBucket: "visorpdf3.appspot.com",
  messagingSenderId: "353951951293",
  appId: "1:353951951293:web:3879467094685cdaa80ad4"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Exportar servicios de Storage y Realtime Database
export const storage = getStorage(app);
export const db = getDatabase(app);
