import React, { useState, useRef } from 'react';
import { storage, db } from '../firebaseConfig';
import { ref, deleteObject, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, remove, update } from 'firebase/database';
import { 
  parseUserAgent, 
  getLatestViewDetails, 
  formatDate, 
  calculateViewDuration, 
  formatPlatform,
  calculateViewStats,
  calculateTotalViewDuration
} from './AdminUploadUtils';
import './AdminUpload.css';

const AdminUploadTable = ({ pdfs, fetchPdfs }) => {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const fileInputRef = useRef(null);

  const handleDeletePdf = async (pdfId, pdfName) => {
    if (!window.confirm(`¿Estás seguro de que quieres eliminar el archivo ${pdfName} y su registro?`)) return;

    try {
      const storageRef = ref(storage, `pdfs/${pdfName}`);
      try {
        await deleteObject(storageRef);
      } catch (storageError) {
        console.error("Error al eliminar el archivo del storage:", storageError);
        if (storageError.code === 'storage/object-not-found') {
          alert('El archivo no se encontró en el storage, pero se eliminará el registro de la base de datos.');
        } else {
          throw storageError;
        }
      }

      const pdfRef = dbRef(db, `pdfs/${pdfId}`);
      await remove(pdfRef);

      alert('PDF y su registro eliminados exitosamente');
      fetchPdfs();
    } catch (error) {
      console.error("Error al eliminar el PDF:", error);
      alert(`Error al eliminar el PDF: ${error.message}`);
    }
  };

  const handleResubmit = (pdfId, newFile) => {
    if (newFile) {
      uploadFile(newFile, pdfId);
    }
  };

  const uploadFile = (fileToUpload, pdfId) => {
    const storageRef = ref(storage, `pdfs/${fileToUpload.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileToUpload);

    uploadTask.on(
      "state_changed",
      () => {},
      (error) => {
        console.error("Resubmit failed:", error);
        alert(`La resubida falló: ${error.message}`);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const pdfRef = dbRef(db, `pdfs/${pdfId}`);
          await update(pdfRef, {
            name: fileToUpload.name,
            url: downloadURL,
            timestamp: new Date().toISOString()
          });
          alert("PDF resubido exitosamente!");
          fetchPdfs();
        } catch (error) {
          console.error("Error updating PDF:", error);
          alert(`Error al actualizar el PDF: ${error.message}`);
        }
      }
    );
  };

  const handleShowDetails = (pdfId) => {
    setSelectedPdf(selectedPdf === pdfId ? null : pdfId);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
    }, (err) => {
      console.error('Error al copiar el enlace: ', err);
    });
  };

  return (
    <div>
      <h2>PDFs Subidos</h2>
      {pdfs.length === 0 ? (
        <p>No hay PDFs subidos aún.</p>
      ) : (
        <table className="pdf-table">
          <thead>
            <tr>
              <th>Estado</th>
              <th>Nombre</th>
              <th>Teléfono</th>
              <th>Observaciones</th>
              <th>Fecha</th>
              <th>Vistas</th>
              <th>Tiempo Total</th>
              <th>Enlaces</th>
              <th>Plataforma</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pdfs.map((pdf) => {
              const latestView = getLatestViewDetails(pdf.viewDetails);
              const parsedUA = latestView ? parseUserAgent(latestView.userAgent) : {};
              const viewStats = calculateViewStats(pdf.viewDetails);
              const totalViewDuration = calculateTotalViewDuration(pdf.viewDetails);
              return (
                <React.Fragment key={pdf.id}>
                  <tr>
                    <td>
                     <span className={`status-indicator ${Object.values(pdf.viewDetails || {}).some(view => view.active) ? 'status-active' : 'status-inactive'}`}></span>
                    </td>
                    <td>{pdf.nombre}</td>
                    <td>{pdf.telefono}</td>
                    <td>{pdf.obs}</td>
                    <td>{latestView ? formatDate(latestView.timestamp) : 'N/A'}</td>
                    <td>{viewStats.uniqueViews}/{viewStats.totalViews}</td>
                    <td>{totalViewDuration}</td>
                    <td>
                      <a href={`/u/${pdf.shortUrl}`} target="_blank" rel="noopener noreferrer">Ver</a>{' '}
                      
                      <button 
                        onClick={() => copyToClipboard(`${window.location.origin}/${pdf.shortUrl}`)}
                        className="copy-button"
                        title="Copiar enlace con tracking"
                      >
                        📋
                      </button>
                    </td>
                    <td>
                      {latestView ? formatPlatform(latestView, parsedUA) : 'N/A'}
                    </td>
                    <td className="action-buttons">
                      <button className="delete-button" onClick={() => handleDeletePdf(pdf.id, pdf.name)}>Borrar</button>
                      <button className="detail-button" onClick={() => handleShowDetails(pdf.id)}>Detalle</button>
                      <input
                        type="file"
                        accept="application/pdf"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={(e) => handleResubmit(pdf.id, e.target.files[0])}
                      />
                      <button className="resubmit-button" onClick={() => fileInputRef.current.click()}>Resubir</button>
                    </td>
                  </tr>
                  {selectedPdf === pdf.id && (
                    <tr>
                      <td colSpan="10">
                        <table className="detail-table">
                          <thead>
                            <tr>
                              <th>Evento</th>
                              <th>Fecha y Hora</th>
                              <th>Duración</th>
                              <th>IP</th>
                              <th>Ubicación</th>
                              <th>Navegador</th>
                              <th>Sistema Operativo</th>
                              <th>Dispositivo</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pdf.viewDetails && Object.values(pdf.viewDetails).map((detail, index) => {
                              const parsedUA = parseUserAgent(detail.userAgent);
                              return (
                                <tr key={index}>
                                  <td>{detail.event === 'opened' ? '🔓 Abierto' : '🔒 Cerrado'}</td>
                                  <td>{new Date(detail.timestamp).toLocaleString()}</td>
                                  <td>{calculateViewDuration(detail.timestamp, detail.closeTimestamp)}</td>
                                  <td>{detail.ip}</td>
                                  <td>
                                    {detail.country}-{detail.city}-{detail.region}<br />
                                    {detail.lat && detail.lon ? `${detail.lat.toFixed(4)}/${detail.lon.toFixed(4)}` : 'No disponible'}
                                  </td>
                                  <td>{parsedUA.browser}</td>
                                  <td>{parsedUA.os}</td>
                                  <td>{parsedUA.device}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminUploadTable;